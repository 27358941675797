import React from 'react'

import formatMoney from 'utils/formatMoney'

interface PriceProps {
  currency: string
  amount: string | number
  lang?: string
  country?: string
  className?: string
  style?: React.CSSProperties
  includeSign?: boolean
}

const Price = ({ currency, amount, lang, country, className, style, includeSign }: PriceProps) => {
  return (
    <div className={className} style={style}>
      {formatMoney(Number(amount), { currency, language: lang, country, includeSign })}
    </div>
  )
}

export default Price
