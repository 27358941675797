import { generateId } from '@packages/unique-string'

import TextSVG, { TextSVGParams } from './TextSVG'
import { pathUtils } from './utils'

export interface TextOnPathSVGParams extends TextSVGParams {
  position: { bezier: number[]; textAlign?: 'left' | 'right' | 'center' }
  noOffset?: boolean
}

export default class TextOnPathSVG extends TextSVG {
  static smallestFontSize = 1 as const

  private gOffsetElement
  private gFilterElement
  private pathElement
  private textElement
  private textPathElement

  public adjustedFontSize
  public boundingBox?: SVGRect

  constructor() {
    super()

    this.adjustedFontSize = 30

    this.gFilterElement = document.createElementNS(TextSVG.ns, 'g')
    this.gOffsetElement = document.createElementNS(TextSVG.ns, 'g')

    const pathElementId = generateId('text-path')

    this.pathElement = document.createElementNS(TextSVG.ns, 'path')
    this.pathElement.setAttributeNS(null, 'id', pathElementId)
    this.pathElement.setAttributeNS(null, 'fill', 'transparent')
    this.gOffsetElement.appendChild(this.pathElement)
    this.gFilterElement.appendChild(this.gOffsetElement)

    this.textPathElement = document.createElementNS(TextSVG.ns, 'textPath')
    this.textPathElement.setAttributeNS(null, 'href', `#${pathElementId}`)

    this.textElement = document.createElementNS(TextSVG.ns, 'text')
    this.textElement.appendChild(this.textPathElement)
    this.gOffsetElement.appendChild(this.textElement)

    this.svgElement.appendChild(this.gFilterElement)
  }

  public async build(params: TextOnPathSVGParams) {
    await this.setFont(params)
    this.setPosition(params)
    this.setText(params)
    this.setOutline(params)
    this.setFontSizeToFitOnPath(params)
    this.setBoundingBox()
    this.setDimensions(params)
    this.applySVGFilters(params)
  }

  private setBoundingBox() {
    this.beginWork()
    this.boundingBox = this.gOffsetElement.getBBox()
    this.finishWork()
  }

  private setDimensions(params: TextOnPathSVGParams) {
    if (!params.noOffset)
      this.gOffsetElement.setAttributeNS(
        null,
        'transform',
        `translate(${0 - this.boundingBox!.x},${0 - this.boundingBox!.y})`
      )
    this.svgElement.setAttributeNS(null, 'width', String(this.boundingBox!.width))
    this.svgElement.setAttributeNS(null, 'height', String(this.boundingBox!.height))
  }

  private setPosition(params: TextOnPathSVGParams) {
    const bezier = params.position.bezier

    this.pathElement.setAttributeNS(null, 'd', pathUtils.bezierToPath(bezier))

    this.textPathElement.setAttributeNS(null, 'text-anchor', pathUtils.getTextAnchor(params.position.textAlign))
    this.textPathElement.setAttributeNS(null, 'startOffset', pathUtils.getStartOffset(params.position.textAlign))
  }

  private setText(params: TextOnPathSVGParams) {
    const text = params.text.value || ''
    this.textPathElement.innerHTML = text
    this.textElement.setAttributeNS(null, 'fill', params.color?.hex || this.defaultValues.color)
    this.textElement.setAttributeNS(null, 'font-family', params.font.family || this.defaultValues.fontFamily)
  }

  private setOutline(params: TextOnPathSVGParams) {
    if (this.hasOutline(params)) {
      this.textElement.setAttributeNS(null, 'stroke', params.outline!.hex!)
      this.textElement.setAttributeNS(null, 'stroke-width', String(params.outline!.width!))
      this.textElement.setAttributeNS(null, 'paint-order', 'stroke')
    } else {
      this.textElement.removeAttributeNS(null, 'stroke')
      this.textElement.removeAttributeNS(null, 'stroke-width')
      this.textElement.removeAttributeNS(null, 'paint-order')
    }
  }

  private setFontSizeToFitOnPath(params: TextOnPathSVGParams) {
    this.beginWork()

    const fontSize = Number((params.font.size || this.defaultValues.fontSize)?.replace('px', ''))
    this.textPathElement.setAttributeNS(null, 'font-size', `${fontSize}px`)

    const text = params.text.value || ''

    const fullWidthPath = this.createFullWidthPathElement(text, fontSize)
    this.svgElement.appendChild(fullWidthPath)

    const fullWidthTextPath = this.createFullWidthTextPathElement(text, fontSize)
    this.textElement.appendChild(fullWidthTextPath)

    const adjustedFontSize =
      pathUtils.fitFontSizeToPath(this.pathElement, this.textPathElement, fullWidthTextPath) ?? fontSize

    this.textElement.removeChild(fullWidthTextPath)
    this.svgElement.removeChild(fullWidthPath)
    this.finishWork()

    this.adjustedFontSize = adjustedFontSize
  }

  private createFullWidthPathElement(text: string, fontSize: number) {
    const fullWidthPath = document.createElementNS(TextSVG.ns, 'path')
    fullWidthPath.setAttributeNS(null, 'id', 'text-path-fullWidthPath')
    fullWidthPath.setAttributeNS(null, 'd', `M 0 0 L 0 ${Math.max(2000, fontSize * (text.length + 1))}`)

    return fullWidthPath
  }

  private createFullWidthTextPathElement(text: string, fontSize: number) {
    const fullWidthTextPath = document.createElementNS(TextSVG.ns, 'textPath')
    fullWidthTextPath.setAttributeNS(null, 'href', '#text-path-fullWidthPath')
    fullWidthTextPath.setAttributeNS(null, 'text-anchor', 'middle')
    fullWidthTextPath.setAttributeNS(null, 'startOffset', '50%')
    fullWidthTextPath.setAttributeNS(null, 'font-size', `${fontSize}px`)
    fullWidthTextPath.appendChild(document.createTextNode(text))

    return fullWidthTextPath
  }

  private applySVGFilters(params: TextOnPathSVGParams) {
    const filter = super.createSVGFilters(params)
    if (filter) {
      this.gFilterElement.style.filter = `url(#${filter.id})`
    }
  }
}
