export const START_CUSTOMIZATION = 'customization/start-customization'
export const START_DESIGN_CUSTOMIZATION = 'customization/start-design-customization'
export const SELECT_ANSWER_WHERE_AVAILABLE = 'customization/select-answer-where-available'
export const SELECT_ANSWER = 'customization/select-answer'
export const SELECT_ANSWERS = 'customization/select-answers'
export const REMOVE_ANSWER = 'customization/remove-answer'
export const UPDATE_TEXT_ANSWER = 'customization/update-choice-text'
export const CREATE_LOGO_ANSWER = 'customization/create-logo-choice'
export const CREATE_TEXT_ANSWER = 'customization/create-text-choice'
export const CREATE_COLOR_ANSWER = 'customization/create-color-answer'
export const CREATE_FONT_SIZE_ANSWER = 'customization/create-font-size-answer'
export const UPDATE_FONT_SIZE_ANSWER = 'customization/update-font-size-answer'
export const UPDATE_PRINT_AREA_LOGO_POSITION_ANSWER = 'customization/update-print-area-logo-position-answer'
export const UPDATE_PRINT_AREA_TEXT_POSITION_ANSWER = 'customization/update-print-area-text-position-answer'
export const UPDATE_COLOR_ANSWER = 'customization/update-color-answer'
export const EDIT_PART = 'customization/edit-part'
export const STOP_EDIT_PART = 'customization/stop-edit-part'
export const SET_QUESTION_DEFAULT_CONFIGURATION = 'customization/set-question-default-configuration'
export const SET_QUESTION_HAS_FILTERED_WORD = 'customization/set-question-has-filtered-word'
export const SET_QUESTION_HAS_REQUIRED_ERROR = 'customization/set-question-has-required-error'
export const SET_SHOW_FINISH_CUSTOMIZATION = 'customization/set-show-finish-customization'
export const DISABLE_SUBMIT = 'customization/disable-submit'
