import { clone, curry, get, setWith } from 'lodash'

import { Translation } from 'cms/translations/types'

import type { CustomizationState } from './reducer'

const updateIn = curry<string, string, CustomizationState, CustomizationState>((path, value, obj) => {
  return get(obj, path) ? setWith(clone(obj), path, value, clone) : obj
})

export type ApplyTranslationsAction = {
  payload: {
    productId: string
    translation: Translation
  }
}

const applyTranslations = (state: CustomizationState, { payload }: ApplyTranslationsAction) => {
  if (!payload.translation) return state

  const customizerProductTranslation = payload.translation.products[payload.productId] || {}

  return Object.values(customizerProductTranslation).reduce((nextState, translation) => {
    return updateIn(translation.key.replace(/\//g, '.'), translation.text?.trim() || translation.from, nextState)
  }, state)
}

export default applyTranslations
