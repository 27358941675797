import { twoDDisplayerActions } from 'customizer/2dDisplayer'

import { DISABLE_PERSONALISATION } from './actionTypes'
import { startingPointConfigurationSelector } from './selectors'

export const disablePersonalisations = () => {
  return {
    type: DISABLE_PERSONALISATION,
  }
}

export const createStartingPoint = () => {
  return async (dispatch, getState) => {
    const startingPointConfig = startingPointConfigurationSelector(getState())
    const imageData = await dispatch(twoDDisplayerActions.generateProductImage(0))

    return {
      configuration: startingPointConfig,
      imageData,
    }
  }
}
